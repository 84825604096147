.wrapper {
  margin: 0;
}

.header {
  padding: 20px 25px 10px 25px;
  background-color: #fff;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  > div:first-child {
    display: flex;
    align-items: center;
  }
}

.headerBottom {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    margin-top: 10px;
    > div {
      margin-right: 20px;
    }
  }
  > div:last-child {
    > div {
      margin-left: 10px;
    }
  }
}

.body {
  padding: 40px 20px;
  background-color: #f5f6fa;
  > div {
    display: flex;
    justify-content: center;
    > div {
      margin: 10px 10px 18px 10px;
    }
  }
}

.card {
  border-radius: 10px;
}

.button {
  border-radius: 5px;
}
