@import "~antd/dist/antd.less";
@import "quill/dist/quill.snow.css";

@checkbox-size: 19px;
@checkbox-color: #5d38db;
@checkbox-check-color: #fff;
@checkbox-check-bg: #d8d8d8;
@checkbox-border-width: 0;
@checkbox-border-radius: 0;

@tabs-hover-color: #5d38db;

@radio-size: 19px;
@radio-top: 0.3em;
@radio-dot-size: @radio-size;
@radio-dot-color: #5d38db;
@radio-solid-checked-color: #e6e8f1;

@link-color: #5d38db;
@link-hover-color: #7857ea;

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  position: static;
}

.ql-container {
  border: solid 1px #ccc !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  &:hover {
    border: solid 1px#5d38db !important;
  }
  &:active {
    border: solid 1px#5d38db !important;
  }
  &:focus {
    border: solid 1px#5d38db !important;
  }
}

.ql-snow.ql-toolbar {
  border: solid 1px #ccc !important;
  border-bottom: none !important;
  display: block !important;
  background: #fff !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 14px;
  font-family: "DM Sans";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 0.5;
}

.ant-form label {
  font-weight: 600;
}
.ant-input-group-addon:first-child {
  border-radius: 5px 0 0 5px !important;
  border-top: solid 2px#c7c8c9 !important;
  border-left: solid 2px#c7c8c9 !important;
  border-bottom: solid 2px#c7c8c9 !important;
  border-right: solid 0.5px #c7c8c9 !important;
  background-color: #ffffff !important;
  height: 52px !important;
  vertical-align: middle;
  > span {
    top: 9px;
    font-weight: normal;
  }
  &:hover {
    border-top: solid 2px#5d38db !important;
    border-left: solid 2px#5d38db !important;
    border-bottom: solid 2px#5d38db !important;
    border-right: solid 0.5px #5d38db !important;
  }
  &:active {
    border-top: solid 2px#5d38db !important;
    border-left: solid 2px#5d38db !important;
    border-bottom: solid 2px#5d38db !important;
    border-right: solid 0.5px #5d38db !important;
  }
  &:focus {
    border-top: solid 2px#5d38db !important;
    border-left: solid 2px#5d38db !important;
    border-bottom: solid 2px#5d38db !important;
    border-right: solid 0.5px #5d38db !important;
  }
}

.ant-tabs-ink-bar {
  height: 2px;
  background: #5d38db !important;
}

.code-editor-tabs {
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
  }
  element.style {
    width: 0;
    left: 0;
    height: 0;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    position: absolute;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav {
    .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
      border-radius: 7px 7px 0 0;
      width: 120px;
    }
    .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: #272822;
      border-radius: 7px 7px 0 0;
      width: 120px;
      .ant-tabs-tab-remove {
        color: rgba(245, 246, 250, 1);
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #878b92 !important;
    text-shadow: 0 0 0.25px #fff;
  }
}

.ant-typography.ant-typography-ellipsis {
  flex: 1;
}

