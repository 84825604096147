.video {
  width: 242px;
  height: 180px;
  border-radius: 15px !important;
  // position: absolute;
  // bottom: 5%;
  // right: 2%;
  overflow: hidden;

  :hover {
    background-color: black;
    cursor: all-scroll;
    filter: brightness(78%);
  }
}
.circle {
  height: 60px;
  width: 60px;
  background-color: #bbb;
  border-radius: 50%;
  position: absolute;
  top: 0;
  display: inline-flex;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  margin: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
}

.videoWrapper {
  width: 242px !important;
  height: 180px !important;
  border-radius: 15px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  background-color: #1c1c1e;
}

.videoWrapperSupervisor {
  width: 173px !important;
  height: 133px !important;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  background-color: #1c1c1e;
  cursor: auto;
}

.videoSz {
  width: 242px;
  height: 180px;
  border-radius: 15px !important;
  z-index: 1000;
  position: fixed;
}

.videoSzSupervisor {
  width: 173px !important;
  height: 133px !important;
  border-radius: 8px !important;
  // position: fixed;
  z-index: 999 !important;
}

.videoSz:hover {
  background-color: black;
  cursor: all-scroll;
  filter: brightness(78%);
  .controlWrapperSz {
    z-index: 99;
    background-color: rgb(25, 25, 25) !important;
    opacity: 1 !important;
    cursor: pointer !important;
  }
}

.videoSzSupervisor:hover {
  background-color: black;
  cursor: all-scroll;
  filter: brightness(78%);
  .controlWrapperSz {
    z-index: 99;
    background-color: rgb(25, 25, 25) !important;
    opacity: 1 !important;
    cursor: pointer !important;
  }
}

.videoDraggable {
  width: 242px;
  height: 180px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
}
.videoSupervisor {
  width: 248px;
  height: 189px;
  border-radius: 15px;
  position: absolute;
  bottom: 25%;
  right: 2%;
  overflow: hidden;
}
.fullscreen {
  width: calc(100vw - 90px);
  height: calc(100vh - 70px);
}

.controlWrapper {
  width: 175px;
  height: 50px;
  position: absolute;
  left: 36px;
  display: flex;
  bottom: 20px;
  // background-color: #1c1c1e;
  // opacity: 50% !important;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.controlWrapperSz {
  width: 135px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  // background-color: #1c1c1e;
  // opacity: 50% !important;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  gap: 25px;
  z-index: 20;
  opacity: 0;
}

.presenter {
  width: 100%;
  display: flex;
  background-color: #4fc181;
  justify-content: center;
  // margin-top: -9px;
  // margin-left: -5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  opacity: 0.8 !important;
}

.presenterSupervisor {
  width: 100%;
  display: flex;
  background-color: #4fc181;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 11px;
  line-height: 21px;
  width: 173px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8 !important;
}

.controls {
  max-width: fit-content;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  fill: transparent;
  .svg {
    width: 21px;
    height: 21px;
    fill: transparent;
    &:hover {
      fill: #1c1c1e;
    }
  }
}

.screenWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: auto 16 / 9;
  height: calc(100% - 165px);
  margin: 0 auto;
}

.noScreenWrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vidWrapper {
  z-index: 9999;
  width: calc(100vw - 90px);
  height: 133px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  > div {
    margin: 12px 6px;
  }
}

.testVidWrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  > div {
    margin-top: 12px !important;
  }
}

.supervisorViewContainer {
  background: #f5f6fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // padding-top: 25px;
}

.titleText {
  padding-top: 25px;
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  opacity: 0.5;
  padding-bottom: 13px;
}

.candidateName {
  position: fixed;
  bottom: 1%;
  left: 2%;
  z-index: 99;
  > h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }
}

.videoName {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 1%;
  margin-left: 12px;
}

.noScreenVideo {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #1c1c1e;
  z-index: 20;
}

.largeCircle {
  height: 150px;
  width: 150px;
  background-color: #bbb;
  border-radius: 50%;
  position: absolute;
  top: 0;
  display: inline-flex;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.8;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 72px;
  font-weight: bold;
}

.vidWrapperWhenNoScreen {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100vw;
  > div:first-child {
    left: 2.7vw;
  }
  > div:last-child {
    right: 5.3vw;
    width: 46% !important;
    height: 65% !important;
    border-radius: 8px !important;
    position: absolute;
    z-index: 999;
  }
  > div:last-child > div {
    width: 100% !important;
    height: 100% !important;
  }
  > div:last-child > div:hover {
    background-color: black;
    filter: brightness(78%);
    .controlWrapperSz {
      width: 135px !important;
      height: 50px !important;
      z-index: 99;
      background-color: rgb(25, 25, 25) !important;
      opacity: 1 !important;
      cursor: pointer !important;
    }
  }
}

.vidWrapperWhenNoScreenSz {
  width: 100% !important;
  height: 100% !important;
}

.videoSzSupervisorWhenNoScreen {
  width: 46% !important;
  height: 65% !important;
  border-radius: 8px !important;
  position: absolute;
  z-index: 999;
}

.videoSzSupervisorWhenNoScreen:hover {
  background-color: black;
  cursor: unset !important;
  filter: brightness(78%);
  .controlWrapperSz {
    z-index: 99;
    background-color: rgb(25, 25, 25) !important;
    opacity: 1 !important;
    cursor: pointer !important;
  }
}

.noScreenVideoWhenNoScreen {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  background-color: #1c1c1e;
  z-index: 20;
  border-radius: 8px;
}

.largeCircleWhenNoScreen {
  height: 150px;
  width: 150px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-flex;
  margin: auto;
  opacity: 0.8;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 72px;
  font-weight: bold;
}

.smallCircleWhenScreen {
  height: 60px;
  width: 60px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-flex;
  margin: auto;
  opacity: 0.8;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
}

.videoWrapperSupervisorWhenNoScreen {
  width: 46% !important;
  height: 65% !important;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  background-color: #1c1c1e;
  position: absolute;
}

.safariScreenShareBtn {
  all: unset;
  margin-top: 8px;
}
