.wrapper {
  margin: 0;
  background-color: #f5f6fa;
}

.header {
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  filter: grayscale(1);
  padding: 30px 25px;
  margin: 0;
}
