.output {
  margin: 0 0 30px 0;
  background-color: #373d4a;
  border-radius: 0 0 5px 5px;
  height: 150px;
  color: #878b92;
  overflow-x: scroll;
  border-top-color: #fff;
  > p {
    padding: 5px 0 0 20px;
    margin: 0;
  }
  > span {
    color: #fff;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfbfbf64;
    border-radius: 3px;

    &:hover {
      background: rgba(255, 255, 255, 0.387);
    }
  }
}
.outputResponse {
  font-size: 12px;
  text-align: justify;
  text-justify: inter-word;
  width: 98%;
  margin-left: 8px;

  pre {
    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf64;
      border-radius: 3px;

      &:hover {
        background: rgba(255, 255, 255, 0.387);
      }
    }
  }
}
.header {
  border-top: 1px solid #878b92;
  padding: 10px 0 20px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #373d4a;
  color: #fff;

  > span {
    margin: 15px 0 0px 30px;
  }
  > .ioButtons {
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: 10px 20px 0 20px;
  }
}

.border {
  box-shadow: 11px -8px 5px 0px rgba(0, 0, 0, 0.75);
}

.settings {
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 15px;

  div > p:first-of-type {
    width: 150px;
    padding: 0 25px 0 0;
    display: inline-block;
    align-self: flex-end;
  }

  > div {
    display: flex;
    flex-direction: row;
  }
}
.response {
  width: 100%;
}
