@import "../../../theme/theme";

.sidebar {
  display: flex;
  flex-direction: column;
  // position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $dashboard-sidebar-width;
  background: #fff;
  border-right: $dashboard-sidebar-border;
  height: 100vh;
  overflow-x: auto;
}

.itemsWrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebarSection {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    padding: 12 24px;
  }
  padding-bottom: 120px;
}

.jobsListWraper {
  // max-height: 40vh;
  // overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    padding: 12 24px;
  }
}

.logoWrapper {
  padding-left: 28px;
  padding-top: 15px;
  padding-bottom: 12px;
}

.separator {
  padding: 14px 28px;
  opacity: 0.5;
}

.unpublishedJobLink {
  opacity: 0.5;
}
