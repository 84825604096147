.support {
  border-radius: 59px;
  background: #000;
  padding: 10px;
  width: 102px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #fff;
  }
}
