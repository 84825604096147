.wrapper {
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  // border-bottom: 1px solid rgba(black, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  // justify-content: space-between;
  z-index: 99;

  > div {
    display: flex;
    justify-content: flex-start;
  }

  > div:last-child {
    display: flex;
    justify-content: flex-end;
  }

  > div:not(:first-child):not(:last-child) {
    display: flex;
    justify-content: center;
  }

  > div > span {
    font-size: 18px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
  }

  > div > span:last-child {
    opacity: 0.5;
    margin-left: 7px;
  }
}

.support {
  a {
    color: #5d38db;
    font-size: 14px;
    font-weight: 900;
  }
}

.line {
  width: 1px;
  height: 77px;
  margin: -20px 26px;
  opacity: 0.2;
  border: solid 1px #979797;
}

.logo {
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  > div > img {
    width: 100%;
    height: 46px;
    border-radius: 5px;
    // margin-top: 10px;
    object-fit: cover;
  }
}

.logo:first-child > span {
  margin-right: auto;
}

.logo:last-child > span {
  margin-left: auto;
}

.time {
  text-align: center;
  // border-left: 2px solid rgba(black, 0.2);
  width: 184px;
}

.timer::before {
  opacity: 0.5;
  content: "Time left:\00a0";
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  // span {
  //   opacity: 0.5;
  // }
}

// .startNow {
//   text-align: center;
//   border-left: 2px solid rgba(black, 0.2);
// }

.titleWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  > span:first-child {
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > span:last-child {
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.headerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
