html,
body {
  font-family: "DM Sans" !important;
  font-size: 14px !important;
}
#root {
  height: 100%;
}
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  /* border: none !important; */
}
.ql-editor {
  /* height: 90px !important; */
  height: auto !important;
}

#testQuill {
  > div {
    .ql-container {
      border: none !important;
    }
  }
}

.FIX__css_quill .ql-editor {
  height: 140px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #9013fe !important;
}

.ant-card-meta-title {
  white-space: normal !important;
}

.ant-card-body,
.ant-modal-header,
.ant-drawer-body,
.ant-drawer-header,
.ant-modal-footer {
  padding: 0 !important;
}

.ant-drawer-header {
  border: none !important;
}
.ql-container {
  border: none !important;
}

.video-js {
  border-radius: 16px;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
}

.video-js .vjs-control-bar {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.video-js .vjs-tech {
  border-radius: 16px;
}

.candidate-screen {
  width: 100%;
  height: 100%;
  > div {
    border-radius: 16px;
    box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  }
}

.video-feed {
  width: 242px !important;
  height: 180px !important;
  border-radius: 15px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
}

.video-feed-supervisor {
  width: 173px !important;
  height: 133px !important;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  cursor: auto;
}

.video-feed-fs {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.videoWrapper {
  width: 242px !important;
  height: 180px !important;
  border-radius: 15px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
  background-color: #1c1c1e;
  position: fixed;
  z-index: 999;
}

.videoWrapper:hover {
  background-color: black;
  cursor: all-scroll;
  filter: brightness(78%);
}

.videoSz {
  width: 242px;
  height: 180px;
  border-radius: 15px !important;
  position: fixed;
  z-index: 999;
}

.videoSz:hover {
  background-color: black;
  cursor: all-scroll;
  filter: brightness(78%);
  .controlWrapperSz {
    z-index: 20;
    background-color: rgb(25, 25, 25) !important;
    opacity: 1 !important;
    cursor: pointer !important;
  }
}

.controlWrapperSz {
  width: 175px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  gap: 25px;
  z-index: 20;
  opacity: 0;
}

.video-feed-no-screen {
  width: 100% !important;
  height: 100% !important;
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0px 6px 19px rgba(128, 152, 213, 0.074983);
}
