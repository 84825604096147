.select {
  // border: 0;
  border: solid 2px #c7c8c9;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  width: 100%;
  &:hover {
    border: solid 2px #5d38db;
  }
  &:active {
    border: solid 2px #5d38db;
  }
  &:focus {
    border: solid 2px #5d38db;
  }
  > div {
    vertical-align: middle !important;
    // border: solid 2px #c7c8c9 !important;
    // background-color: #ffffff !important;
    // border-radius: 5px !important;
    // &:hover {
    //   border: solid 2px #5d38db !important;
    // }
    // &:active {
    //   border: solid 2px #5d38db !important;
    // }
    // &:focus {
    //   border: solid 2px #5d38db !important;
    // }
    > span {
      display: flex;
      align-items: center;
    }
    > input {
      font-weight: normal !important;
    }
  }
}

.removeBorder {
  input {
    border: none !important;
  }
}

.default {
  height: 52px !important;
  > div {
    // height: 52px !important;
    padding: 5px 0 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-weight: bold !important;
  }
}

.small {
  height: 45px !important;
  > div {
    // height: 45px !important;
    padding: 5px 0 !important;
    padding-left: 13px !important;
    padding-right: 13px !important;
    font-weight: bold !important;
  }
}
