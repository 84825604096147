.select {
  > div {
    vertical-align: middle !important;
    border: solid 2px #c7c8c9 !important;
    background-color: #ffffff !important;
    border-radius: 5px !important;
    &:hover {
      border: solid 2px #5d38db !important;
    }
    &:active {
      border: solid 2px #5d38db !important;
    }
    &:focus {
      border: solid 2px #5d38db !important;
    }
    > span {
      display: flex;
      align-items: center;
    }
    > input {
      font-weight: normal !important;
    }
  }
}

.removeBorder {
  input {
    border: none !important;
  }
}

.default {
  > div {
    height: 52px !important;
    padding: 5px 0 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-weight: bold !important;
  }
}

.small {
  > div {
    height: 45px !important;
    padding: 5px 0 !important;
    padding-left: 13px !important;
    padding-right: 13px !important;
    font-weight: bold !important;
  }
}
