.wrapper {
  top: 0 !important;
  left: 0 !important;
  height: 40px !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  z-index: 99 !important;
}

.online {
  background: #32cb76 !important;
}

.offline {
  background: #fc4858 !important;
}
