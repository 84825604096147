.wrapper {
  display: flex;
  width: 600px;
  position: absolute;
  top: 7.5vh;
  left: 15vw;
  right: 0vw;
  flex-direction: column;
  margin: 65px 240px;
  user-select: none;
  > p {
    overflow-y: visible;
    width: 100%;
  }
}

.submitAnswer {
  display: flex;
  justify-content: flex-end;
}

.submitAnswerBtn {
  margin: 34px 0;
  width: 160px;
}
.wrapper {
  > p {
    inline-size: auto;
    overflow-wrap: break-word;
    margin-bottom: 30px;
  }
}
