.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: baseline;
}
.answerText {
  margin-left: 2px;
}

.icon {
  // margin: 5px;
}
