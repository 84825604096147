@import "../../../theme/theme";

.wrapper {
  top: 0 !important;
  left: 0 !important;
  height: $top-banner-height !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #5d38db !important;
  color: #fff !important;
  z-index: 99 !important;
  button {
    border-width: 2px;
    margin-left: 23px;
    padding: 0px 10px;
    border-radius: 5px;
    height: 28px;
    background: transparent;
    color: #fff;
    &:hover {
      background: #fff;
      color: #5d38db;
      border-color: #fff;
    }
  }
}
