.expandBtn {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #7f7f7f;
  background-color: #ffff;
}
.drawerBody {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 30px;
  height: inherit;
}

.drawerHeader {
  padding: 42px 42px 30px 40px;
  display: flex;
  justify-content: space-between;
  > span {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.ansSectionHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  > div {
    margin-top: 10px;
  }
}

.wrapperQues {
  margin-bottom: 20px;
}
