.description {
  margin-top: 14px;
  opacity: 0.5;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
}
