.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background-color: #f5f6fa;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 60%;
    width: 100%;
  }
}

.waitingText {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #000000;
  opacity: 0.5;
}

.instructionsText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
}

.changeDeviceWrapper {
  display: flex;
  margin-top: 10px;
  width: 356px;
  flex-direction: row;
  justify-content: space-around;

  &__content {
    align-items: center;
  }
}

.VolumeIndicator {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 12px;
  align-self: flex-start !important;
  padding: 0 30px 0px 30px;

  &__active {
    height: 10px;
    width: 5px;
    background-color: #6dd400;
    border-radius: 5px;
  }
  &__notActive {
    height: 10px;
    width: 5px;
    background-color: #d8d8d8;
    border-radius: 5px;
  }
}

.changeDeviceWrapper {
  display: flex;
  margin-top: 10px;
  width: 356px;
  flex-direction: row;
  justify-content: space-around;

  &__content {
    align-items: center;
  }
}

.AudioAndVideo {
  margin-top: 10px;
  margin-bottom: 30px;

  &__content {
    display: flex;
    flex-direction: row;
    gap: 23px;

    &__button {
      border: solid 1px #5d38db;
      color: #5d38db !important;
      height: 112px;
      width: 161px;
      border-radius: 6px;
      position: relative;
      top: 0;
      left: 0;

      &__checkmark {
        position: absolute;
        top: 5px;
        left: 130px;
        z-index: 2;
      }
      &__checkmarkmic {
        position: absolute;
        top: 5px;
        left: 130px;
      }
    }

    &__iconwrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        font-size: 12px;
        margin-top: 11px;
      }
    }
  }
}

.submitSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.beforeScheduleTime {
  text-align: center;
  font-size: 18px;
}
