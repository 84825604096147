.output {
  padding: 5px 0 0 20px;
  margin: 0 0 30px 0;
  background-color: #373d4a;
  border-radius: 0 0 5px 5px;
  height: 150px;
  color: #878b92;
  overflow-x: scroll;
  > p {
    padding: 0;
    margin: 0;
  }
  > span {
    color: #fff;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(192, 192, 192, 0.392);
    border-radius: 3px;

    &:hover {
      background: rgba(255, 255, 255, 0.387);
    }
  }
}
.header {
  padding: 5px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #373d4a;
  color: #fff;
  border-top: 1px solid #fff;

  > span {
    margin: 15px 0 0px 30px;
  }
  > .ioButtons {
    display: flex;
    justify-content: space-between;
    width: 20%;
    margin: 10px 20px 0 20px;
  }
}

.border {
  box-shadow: 11px -8px 5px 0px rgba(0, 0, 0, 0.75);
}
