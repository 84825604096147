.wrapper {
  margin: 0;
}

.header {
  height: 81px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0482054);
  display: flex;
  align-items: center;
  justify-content: space-around;
  > div:nth-child(2) {
    align-self: center;
  }
  > div:last-child {
    display: flex;
    align-items: center;
  }
}

.body {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  padding: 40px 20px;
}

.content {
  padding: 40px 390px 30px 0;
}
