.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.radioGroupWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  p {
    margin: 0 13px;
  }
}
