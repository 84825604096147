.wrapper {
  margin: 0;
}

.sidebar {
  position: absolute;
  width: 250px;
  left: 0;
  top: 0;
  bottom: 0;
  background: #ffffff;
  border: 1px solid #e6e8f1;
  box-sizing: border-box;
  box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
  padding: 20px 30px;
  z-index: 999;
}

.search {
  position: absolute;
  top: 0;
  left: 250px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background-color: #fff;
}

.content {
  position: absolute;
  top: 50px;
  left: 250px;
  bottom: 0;
  right: 0;
  > div {
    padding-top: 30px;
  }
}
