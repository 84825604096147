.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  > div {
    width: 49%;
  }
  > span {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.86;
    letter-spacing: normal;
    margin: 0 0 0 11px;
  }
}
