.body {
  display: flex;
}
.submitAnswersBtn {
  margin: 0px 20px;
  position: fixed;
  bottom: 15vh;
  width: max-content;
  height: max-content;
  padding: 10px 30px;
  background-color: #5d38db;
  color: white;
  border-radius: 5px;
}

.avModule {
  height: 100vh;
  z-index: 50;
}

.submitSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.infoWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.supportLink {
  bottom: 35px;
  right: 45px;
  position: fixed;
  z-index: 9999;
}

.spinner {
  > div {
    margin: 50vh 50vw;
    transform: translateY(-50%);
  }
}
