$dark-purple: #5d38db;
$purple: #9013fe;
$light-purple: #f4e8ff;
$black: #000000;
$dark-gray: #535454;
$light-gray: #c7c8c9;
$lightest-gray: #f5f6fa;
$white: #ffffff;

$link-color: #000;

$background-color: $lightest-gray;

// inputs
$input-border-color: #edeef0;

$dashboard-border-color: #e6e8f1;
$dashboard-link-color: $link-color;
$dashboard-active-link-bg: $light-purple;
$dashboard-active-link-color: $purple;

// Header
$header-bg: #fff;
$header-border-bottom: 1px solid $dashboard-border-color;
