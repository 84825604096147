@import "../../../theme/mixins/authWrapper";
@import "../../../theme/mixins/input";
@import "../../../theme/mixins/label";
@import "../../../theme/colors";

.wrapper {
  @include authWrapper;
  min-height: 100vh;
  padding-top: 0px;
  flex-direction: column;
  > h1 {
    margin: 30px 0;
  }
}

.input {
  @include input {
    width: 100%;
  }
}

.tempPassForm {
  margin-bottom: 80px;
}

.logo {
  margin-top: 40px;
  width: 80px;
}

.mollyLogoText {
  width: 80px;
  position: absolute;
  bottom: 20px;
}
.btn {
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  background: #5d38db;
  height: 50px;
  &:focus,
  &:hover {
    background-color: $purple;
    color: $white;
  }
}

.label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}
