.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin-top: 20px;
}

.link {
  padding: 0 5px;
  font-weight: bold;
  color: #5d38db;
  &:hover {
    color: #7857ea;
  }
}
