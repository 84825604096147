.card {
  margin: 0 0;
  border-radius: 14px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  :global(.ant-card-head) {
    padding: 0 0 10px;
    min-height: auto;
    margin-bottom: 0;
  }
  :global(.ant-card-head-title) {
    padding: 0;
    margin: 0;
  }
  :global(.ant-card-extra) {
    padding: 0;
  }
}

.small {
  padding: 14px 16px;
}

.medium {
  padding: 18px 24px;
}

.large {
  padding: 30px 32px;
}

.soft {
  box-shadow: 0px 1px 2px 1px rgba(128, 152, 213, 0.167827) !important;
}

.softer {
  box-shadow: 0 6px 19px 0 rgba(128, 152, 213, 0.08);
}

.softest {
  box-shadow: 0 9px 21px 0 rgba(128, 152, 213, 0.08);
}

.no-shadow {
  box-shadow: none;
}

.no-border {
  border: none;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.noBoxProps {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}
