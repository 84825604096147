.wrapper {
  bottom: 0;
  padding: 10px 20px;
  position: fixed;
  display: flex;
  align-items: center;
  background-color: white;
  // justify-content: space-between;
  z-index: -1;
}

.footerLogo {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  flex: 1;
  img {
    width: 85px;
    height: 30px;
    margin: 9px 0 0;
    object-fit: contain;
    opacity: 0.5;
  }
}
