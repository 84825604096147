@import "../../../theme/theme";

// .searchInput {
//   @include input {
//     border: none !important;
//     background: none;
//     outline: none;
//     font-size: 16px;

//     &:focus {
//       border: none !important;
//       box-shadow: none !important;
//     }
//   }
//   input {
//     height: 60px !important;
//   }
// }
:global(.molly__search-header .ant-select-selector) {
  height: 100% !important;
  border: none !important;
}
.searchDropdown {
  width: 100%;
  border: none;
  height: 50px;
  font-size: 16px;
  input {
    border: none !important;
    height: 50px !important;
  }
  > div {
    > span:last-child {
      > span {
        position: relative;
        top: 10px;
      }
    }
  }
}
.inputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchPlaceholder {
  display: flex;
  cursor: pointer;
  opacity: 0.8;
  svg {
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 10px;
  }
}

.searchBox {
  form {
    display: flex;
    position: relative;
    input {
      flex: 1;
      font-size: 22px;
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 3px 10px;
      padding-right: 37px;
    }
    :global(button.ais-SearchBox-submit) {
      display: none;
    }
    :global(button.ais-SearchBox-reset) {
      position: absolute;
      right: 10px;
      top: 9px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.noResults {
  text-align: center;
  opacity: 0.5;
  margin: 0;
  padding: 10px;
}
.hits {
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    li a {
      display: block;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      padding: 11px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      margin: 6px 0;
      // box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
      background: #fff;

      &:hover {
        color: #5d38db;
        text-decoration: underline;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
}
