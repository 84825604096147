.footer {
  display: flex;
  align-items: center;
  bottom: 0;
  margin: 0 0 15px 0;
  position: absolute;
}

.rightPad {
  padding-right: 15px;
}
