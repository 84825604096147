.wrapper {
  top: 77px;
  width: 16%;
  height: 70vh;
  padding: 34px 0px;
  position: fixed;
  overflow: auto;
}

.question {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 900;
  padding: 5px 35px;
  margin: 2px 0px;
  > span {
    display: inherit;
    padding: 5px;
  }
  > span:first-child {
    color: #32cb76;
    font-size: 20px;
  }
  > span:hover:nth-child(2) {
    color: #32cb76;
  }
  :hover {
    cursor: pointer;
  }
}

.active {
  background-color: rgba(93, 56, 219, 0.0570615);
  border-radius: 0px 5px 5px 0px;
  color: #000;
}
