.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}

.no-border {
  border: none;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-box-props {
  padding: 0;
  margin: 0;
  border: none;
}

.bold {
  font-weight: bold;
}
