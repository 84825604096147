.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    margin-left: 0 !important;
  }
}

.textarea {
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  &:hover {
    border: solid 1px #5d38db !important;
  }
  &:active {
    border: solid 1px #5d38db !important;
  }
  &:focus {
    border: solid 1px #5d38db !important;
  }
  // margin-bottom: 34px;
}
