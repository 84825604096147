.heading {
  margin-bottom: 0;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 18px;
}
