.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.img {
  width: 132px;
}
.heading {
}
.crop {
  width: 41px;
  overflow: hidden;
}
