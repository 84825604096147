.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 10px;
  :not(:last-child) {
    margin-right: 0;
  }
  > div {
    margin-bottom: 10px;
  }
}
