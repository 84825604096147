@import "../../../theme/theme";

.wrapper {
  background: $header-bg;
  padding: 10.5px 20px;
  border-bottom: $header-border-bottom;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  > div {
    &:first-child {
      margin-right: 20px;
    }
  }
}
