.wrapper {
  margin: 0;
  background-color: #f5f6fa;
}

.header {
  padding: 0 30px;
  > div:first-child {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      justify-content: space-around;
    }
  }
  > div:last-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
}

.content {
  padding: 40px 35px;
  margin: 0;
  > div {
    display: flex;
    justify-content: space-evenly;
  }
}
