.disabledInput {
  border-radius: 5px;
  border: solid 2px #c7c8c9;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  &:hover {
    border: solid 2px #5d38db !important;
  }
  &:active {
    border: solid 2px #5d38db !important;
  }
  &:focus {
    border: solid 2px #5d38db !important;
  }
}

.default {
  height: 52px;
  padding: 14px 34px 13px 15px;
}

.small {
  height: 45px;
  padding: 10px 15px;
}
