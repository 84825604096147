@import "../../../theme/theme";
.link {
  border-left: 4px solid transparent;
  padding: 16px 24px;
  line-height: normal;
  min-height: 50px;
  color: $dashboard-link-color;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.active {
  border-left: $dashboard-link-border-active;
  background: $dashboard-active-link-bg;

  color: $dashboard-active-link-color;
}
.icon {
  margin-right: 15px;
  display: inline-block;
  font-size: 14px;
}
