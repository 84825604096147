@import "../../theme/mixins/authWrapper";
@import "../../theme/mixins/label";
@import "../../theme/mixins/input";
@import "../../theme/colors";

.wrapper {
  background: $background-color;
}

.form {
  background: #fff;
  padding: 45px 60px;
  border-radius: 14px;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);
  label {
    @include label;
  }
}

.input {
  @include input {
    width: 100%;
  }
}

.button {
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  background-color: $purple;
  height: 50px;
  &:hover {
    background-color: $dark-purple;
  }
}

.logo {
  margin: 20px 0 30px;
  width: 80px;
}
.logoWrapper {
  display: flex;
  justify-content: center;
}

.mollyLogoText {
  width: 80px;
  margin: 40px 30px;
}

.availability {
  display: flex;
  align-items: center;
}
.availabilityIndicatorSuccess {
  margin-top: -20px;
  margin-bottom: 20px;
  color: green;
}
.availabilityIndicatorFailed {
  margin-top: -20px;
  margin-bottom: 20px;
  color: tomato;
}
.heading {
  text-align: center;
  margin-top: 40px;
}
.select {
  // @include input {
  //   width: 100%;
  //   height: 50px;
  // }
  > div {
    height: 100%;
  }
}

.nameFields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 49%;
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > div {
    width: auto;
  }
}

.button {
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  background: #5d38db;
  height: 50px;
  &:hover {
    background: #5d38db;
  }
}

.fieldLabels {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}
