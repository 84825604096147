@import "../../../theme/mixins/authWrapper";
@import "../../../theme/mixins/input";
@import "../../../theme/mixins/label";
@import "../../../theme/colors";

.wrapper {
  @include authWrapper;
  min-height: 100vh;
  flex-direction: column;
  > h2 {
    margin: 20px 0 30px;
  }
}

.logo {
  width: 80px;
  margin: 30px 0 15px 0;
  top: 30px;
}

.mollyLogoText {
  width: 80px;
  margin: 30px 0;
  bottom: 21px;
}

.input {
  @include input {
    width: 100%;
  }
}

.field {
  margin-bottom: 20px;
}

.thankYouAlert {
  width: 400px;
  margin-bottom: 30px;
}

.btn {
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  background: #5d38db;
  height: 50px;
  &:focus,
  &:hover {
    background-color: $purple;
    color: $white;
  }
}
