.wrapper {
  margin: 0;
  background-color: #f5f6fa;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  > div {
    margin-top: 20px;
  }
}

.content {
  padding: 30px 18px;
  margin: 0;
}
