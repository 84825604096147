.wrapper {
  margin: 0;
}

.sidebar {
  position: absolute;
  width: 305px;
  min-height: 1055px;
  left: 0;
  top: 62px;
  background: #ffffff;
  border: 1px solid #e6e8f1;
  box-sizing: border-box;
  box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
  padding: 20px 30px;
}

.body {
  position: absolute;
  width: 100%;
  min-height: 1055px;
  left: 305px;
  top: 62px;
  background: #ffffff;
  padding: 20px 30px;
}

.bodyHeader {
  padding: 50px 110px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > div:first-child {
    margin-right: 35px;
  }
}

.bodyContent {
  padding: 50px 110px 0 110px;
}

.bodyFooter {
  padding: 0 110px;
}

.formItem {
  > div:first-child {
    margin-bottom: 9px;
  }
}
