@import "../settings.scss";

.tag {
  border-radius: 10px;
  font-size: 12px;
}
.primary {
  background: rgba($prim-color, 0.3);
  border: none;
  color: $prim-color;
}
