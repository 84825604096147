.title {
  padding: 0;
}

h1 {
  font-size: 45px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -1.2px;
}

h2 {
  font-size: 36px;
  margin-bottom: 5px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

h3 {
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h4 {
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h5 {
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.normal {
  font-weight: 500;
}

.light {
  font-weight: normal;
}
