.input {
  border-radius: 5px;
  border: solid 2px #c7c8c9;
  background-color: #ffffff;
  &:hover {
    border: solid 2px #5d38db !important;
  }
  &:active {
    border: solid 2px #5d38db !important;
  }
  &:focus {
    border: solid 2px #5d38db !important;
  }
}

.default {
  height: 52px;
  padding: 14px 34px 13px 15px;
}

.small {
  height: 45px;
  padding: 10px 15px;
}
