@import "../../../theme/mixins/authWrapper";
@import "../../../theme/mixins/input";
@import "../../../theme/mixins/label";
@import "../../../theme/colors";

.wrapper {
  @include authWrapper;
  flex-direction: column;
  min-height: 100vh;
  > h1 {
    margin: 30px 0;
  }
}

.input {
  @include input {
    width: 100%;
  }
}

.logo {
  width: 80px;
}

.mollyLogoText {
  width: 80px;
  bottom: 20px;
  margin-top: 21px;
  margin-bottom: 21px;
}
.btn {
  width: 100%;
  font-weight: bold;
  border-radius: 5px;
  height: 50px;
  &:focus,
  &:hover {
    background-color: $purple;
    color: $white;
  }
}
